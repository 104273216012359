<template>
  <div :class="['productPage view', routeClass ]" >

      
      <div v-if="routeClass == 'zenotof7600'" class="animation_stage one">
            <img class="logo" src="/imgs/BeExtraordinary-Logo-480x62.png" />
          
            <div class="slideIn gradBG"></div>
      </div>
      <div v-if="routeClass == 'biophase'" class="animation_stage one">
            <img class="logo" src="/imgs/TakeCharge-Logo-342x62.png" />
          
            <div class="slideIn gradBG"></div>
      </div>
      <div v-if="routeClass == 'oneomics'" class="animation_stage one">
            <div class="logo oneomics">
                One cloud. Countless opportunities.
            </div>
          
            <div class="slideIn gradBG"></div>
      </div>

<!-- Product Content -->
    <div class="animation_stage two">
        
        <div v-if="routeClass != 'biophase'" class="gradBG" style="opacity:0.3; width: 100%; height: 100%; position:absolute; z-index:0;"></div>
        <div v-if="routeClass == 'biophase'" class="gradBG" style="opacity:0.6; width: 100%; height: 100%; position:absolute; z-index:0;"></div>
        
        <!-- Product Image -->
        <div id="visual">
            <img style="position:relative; z-index:1;" v-if="routeClass == 'zenotof7600'" src="imgs/ProductImg_ZenoTOF.png">
            <img style="position:relative; z-index:1;" v-if="routeClass == 'oneomics'" src="imgs/ProductImg_OneOmics.png">
            <img style="position:relative; z-index:1;" v-if="routeClass == 'biophase'" src="imgs/ProductImg_BioPhase.png">
            <div class="tagLine">
                <img v-if="routeClass == 'zenotof7600'" class="logo zenotof" src="/imgs/BeExtraordinary-Logo-480x62.png" />
                
                <div v-if="routeClass == 'oneomics'" class="logo oneomics">
                    One cloud. <br>
                    Countless opportunities.
                </div>
                <img v-if="routeClass == 'biophase'" class="logo biophase" src="/imgs/TakeCharge-Logo-342x62.png" />

                <div class="lensInfo">
                    Uncover a different perspective by moving the internal lens over the indicators. 
                    <span style="font-weight: bold;" v-if="routeClass != 'oneomics'"> 
                        Plus,&nbsp;while you move the lens around to explore, keep a look out for hidden stories!
                    </span>
                </div>
            </div>
        </div>
        

        <!-- Connecting Lines -->
        <transition-group name="list-lines" mode="out-in">
            <connecting-lines 
                v-for="(story, index) in storyGroup"
                :key="story"
                :listPos="index"
                :story="story"
                :product="product"
                :storyInLens="storyInLens.sid"
                :class="'index'+index"
            />
        </transition-group>
        
        <!-- Hidden Story Connecting Lines -->
        <transition-group name="list-hidden-lines" mode="out-in">
            <connecting-lines 
                v-for="(story, index) in hiddenStoryGroup"
                :key="story"
                :listPos="index"
                :story="story"
                :product="product"
                :storyInLens="storyInLens.sid"
                :class="'index'+index"

                v-show="storyInLens.sid == story.sid"
            />
        </transition-group>
        

        <!-- Story Column -->
        <div id="stories" class="">

            <div :class="['storyFlip', { 'hide': insideStoryCheck } ]">
                <!-- Story List -->
                <transition-group name="list-stories" mode="out-in">
                    <story-block 
                        v-for="(story, index) in storyGroup"
                        :key="story"

                        :story="story"
                        :prod="product"
                        :intLens="storyInLens"  
                        
                        v-on:viewInternalStory="displayInternalStory($event, true)"
                        
                        :class="['index'+index, {'active gradBG': storyInLens.sid == story.sid }]"

                    />
                </transition-group>
            </div>


            <!-- InternalStory -->
            <div :class="['insideStory gradBG', { 'show': insideStoryCheck } ]">
                <button class="closeBtn" @click="displayInsideStory = false" aria-label="Return to external lens">
                    <span>
                        <span class="button-left"></span>
                        <span class="button-right"></span>
                    </span>
                </button>
                <div v-if="insideStory[0].int.vid == ''" class="insideStoryContent">
                    <div class="visualHeader">
                        <h1 v-html="insideStory[0].int.title"></h1>
                        <img :src="insideStory[0].int.img" alt=""/>
                    </div>
                    <div class="storyText">
                        <div class="associate" v-for="(assoc, index) in associateStory" :key="index">
                            <h2 v-html="assoc.name"></h2>
                            <h3 v-html="assoc.title"></h3>
                        </div>
                        <p v-html="insideStory[0].int.story"></p>
                    </div>
                </div>
                <div v-if="insideStory[0].int.vid != ''" class="insideStoryContent">
                    <div  class="visualHeader">
                        <h1 v-html="insideStory[0].int.title"></h1>
                        <img class="clickable" @click="openVideo" :src="insideStory[0].int.img" alt=""/>
                    </div>
                    <div class="storyText">
                        <div class="associate" v-for="(assoc, index) in associateStory" :key="index">
                            <h2 v-html="assoc.name"></h2>
                            <h3 v-html="assoc.title"></h3>
                        </div>
                        <button class="videoBtn" @click="openVideo">{{ insideStory[0].int.story }}</button>
                    </div>
                </div>
            </div>


        <!-- Pagination -->
        <div class="pagination">
            
            <div>
            <button v-if="currentPg != 1" @click="prevPg">Prev</button>
            </div>
                
            <div>{{currentPg}} of {{ pageCount }} </div>
                
            <div>
                <button v-if="currentPg != pageCount" @click="nextPg">Next</button>
            </div>


        </div>



        </div>
        <!-- Story Column :: End -->




        <div id="interaction">
            
            <ul class="storyLinks">
                <li v-for="story in storyGroup" :key="story" :style="'top: '+ story.pos.y +'px; left: '+ story.pos.x +'px;'">
                    <button :class="{ 'noclick' : story.type == 'extonly' }" @click="displayInternalStory(story.sid, true)" :aria-label="story.sid"></button>
                </li>
                <!-- <li :class="story.sid != storyInLens.sid ? 'hid den' : ''" v-for="story in hiddenStoryGroup" :key="story" :style="'opacity:1; top: '+ story.pos.y +'px; left: '+ story.pos.x +'px;'"> -->
                <li :class="story.sid != storyInLens.sid ? 'hidden' : ''" v-for="story in hiddenStoryGroup" :key="story" :style="'top: '+ story.pos.y +'px; left: '+ story.pos.x +'px;'">
                    <button @click="displayInternalStory(story.sid, true)" :aria-label="story.sid"></button>
                </li>
            </ul>

            <!-- Lens -->
            <lens></lens>


        </div>

    </div>

  </div>
</template>


<script>
// @ is an alias to /src
import Lens from "@/components/lens.vue";
import StoryBlock from "@/components/storyBlock.vue";
import ConnectingLines from '../components/ConnectingLines.vue';


export default {
    name: "products",
    props: {
        apptop: Number
    },
    components: {
        // ConnectLine: E,
        // ConnectLineHidden: F,
        StoryBlock,
        Lens,
        ConnectingLines
    },
    data: function() {
        return {
            // product: "zenotof7600",
            display: true,
            storyCount: 3,
            // currentPg: 1,
            showModal: false,
            displayInsideStory: false,
            insideStory: [
                { int: {
                    title: '',
                    story: ''
                }
            }]
        }
    },
    computed: {
        associateStory() {
            
            if (Array.isArray(this.insideStory[0].int.associate) ) {
                let associateNames = this.insideStory[0].int.associate;
                let associateTitles = this.insideStory[0].int.associate_title;
                let associateArr = []
                
                
                associateNames.forEach( (val, key) => {
                    associateArr.push( {
                        name: val,
                        title: associateTitles[key]
                    });
                });
                return associateArr;
            }
            return [{
                    name: this.insideStory[0].int.associate,
                    title: this.insideStory[0].int.associate_title
                }];
        },
        currentPg() {
            return this.$store.state.currentPg;
        },

        product: function() {
            return this.$route.name.toLowerCase().replace(' ', '');
        },
        modalStory: function() {
            return "" != this.storyInLens.sid ? {
                ex: {
                    title: this.storyInLens.story.ex.title,
                    story: this.storyInLens.story.ex.story
                },
                int: {
                    title: this.storyInLens.story.int.title,
                    story: this.storyInLens.story.int.story
                }
            } : {
                ex: {
                    title: "",
                    story: ""
                },
                int: {
                    title: "",
                    story: ""
                }
            }
        },
        insideStoryCheck() {
            if (this.displayInsideStory && this.$store.state.viewingStory != '') {
                return true;
            }
            return false;
        },
        storyInLens: function() {
            var t = this.storyGroup.concat(this.hiddenStoryGroup)
            , e = 40
            , n = this.$store.state.lensPos.x
            , r = this.$store.state.lensPos.y
            , i = n - e
            , o = n + e
            , s = r - e
            , c = r + e
            , a = t.filter((function(t) {
                return t.pos.x >= i && t.pos.x <= o && t.pos.y >= s && t.pos.y <= c
            }
            ));

            if (a.length > 0) {
                this.displayInternalStory(a[0].sid);
            }

            if (a.length > 0) { 
                this.$store.commit("updateStoryView", a[0]);
                return {
                    sid: a[0].sid,
                    story: a[0],
                    hiddenStoryReveal: {
                        min: i,
                        max: o,
                        ymin: s,
                        ymax: c
                    }
                }
            } else {
                this.$store.commit("clearStoryView");
                this.clearStoryFlip();
                return {
                    sid: "",
                    story: "",
                    hiddenStoryReveal: {
                        min: i,
                        max: o,
                        ymin: s,
                        ymax: c
                    }
                };
            }
        },
        productStories: function() {
            var t = Object.entries(this.$store.state.products[this.product].stories)
            , e = [];
            return t.forEach((function(t) {
                e.push(t[1])
            }
            )),
            e
        },
        stories: function() {
            var t = []
            , e = [];
            return this.productStories.filter((function(n) {
                "hidden" != n.type ? e.push(n) : t.push(n)
            }
            )),
            {
                visible: e,
                hidden: t
            }
        },
        totalVisibleStories: function() {
            return this.stories.visible.length
        },
        totalhiddenStories: function() {
            return this.stories.hidden.length
        },
        pageCount: function() {
            var t = this.totalVisibleStories
            , e = this.storyCount;
            return Math.ceil(t / e)

        },
        pgIndexStart: function() {
            return this.currentPg > 1 ? (this.currentPg - 1) * this.storyCount : 0
        },
        pgIndexEnd: function() {
            return this.pgIndexStart + this.storyCount > this.totalVisibleStories ? this.totalVisibleStories : this.pgIndexStart + this.storyCount
        },
        storyGroup: function() {
            return this.stories.visible.slice(this.pgIndexStart, this.pgIndexEnd)
        },
        hiddenStoryGroup: function() {
            
            // COMPILED CODE
            // var t = this.totalhiddenStories
            // , e = this.storyCount - 1
            // , n = Math.ceil(t / e)
            // , r = this.pgIndexStart
            // , i = this.pgIndexStart + n;
            // return this.currentPg > 1 && (r = (this.currentPg - 1) * n),
            // r + n > this.totalVisibleStories && (i = this.totalVisibleStories),
            // this.stories.hidden.slice(r, i)
          
            
            // ORIGINAL
            // let l = this.totalhiddenStories,
            // s = this.pageCount;
            // let hStoriesPerPg = Math.ceil(l/s);
            
            
            // let pgIndexStart = this.pgIndexStart;
            // let pgIndexEnd = this.pgIndexStart + hStoriesPerPg;

            // if ( this.currentPg > 1) {
            //     pgIndexStart = (this.currentPg - 1) * hStoriesPerPg;
            // }
            // if ((pgIndexStart + hStoriesPerPg) > this.totalVisibleStories) {
            //     pgIndexEnd = this.totalVisibleStories;
            // }
            // return this.stories.hidden.slice(pgIndexStart, pgIndexEnd);


            // VERSION 2
            let l = this.totalhiddenStories,
            s = this.pageCount;
            let hStoriesPerPg = Math.ceil(l/s);
            
            
            let pgIndexStart = this.currentPg - 1;

            if ( pgIndexStart > 0) {
                pgIndexStart = (this.currentPg - 1) * hStoriesPerPg;
            }

            let pgIndexEnd = pgIndexStart + hStoriesPerPg;

            
            if ((pgIndexStart + hStoriesPerPg) > this.totalhiddenStories) {
                pgIndexEnd = this.totalhiddenStories;
            }


            return this.stories.hidden.slice(pgIndexStart, pgIndexEnd);
        },
        storyFade: function() {
            return "" != this.$store.state.viewingStory
        },
        routeClass() {
            return this.$route.name.toLowerCase().replace(' ', '');
        }
    },
    mounted: function() {
        var t = this;
        setTimeout((function() {
            t.display = !0
        }), 1e3)

    },
    methods: {
        clearStoryFlip() {
            this.displayInsideStory = false;
        },

        nextPg: function() {
            this.currentPg <= this.pageCount - 1 && (this.$store.commit("clearStoryView"),
            this.$store.commit("pageIncrease"))
        },
        prevPg: function() {
            this.currentPg >= 2 && (this.$store.commit("clearStoryView"),
            this.$store.commit("pageDecrease"))
        },
        toggleView: function(t, e) {
            this.$store.commit("toggleStoryView", {
                prod: t,
                story: e.sid
            })
        },
        toggleHiddenView: function() {
            this.showModal = !this.showModal
        },

        displayInternalStory: function(sid, showIt) {
            
                let originalsid = this.insideStory[0].sid;

            // console.log(sid);
            var prodStories = this.storyGroup.concat(this.hiddenStoryGroup);

            var storyValue = {
                type: '',
                pos: { x: 0, y:0 }
            };
            
            this.insideStory = prodStories.filter((function(story) {
                if (story.sid == sid) {
                    storyValue = { type: story.type, pos: story.pos };
                    return story
                }
            }));


            //Check the type of story to determine if we show the internal view
            if ( (storyValue.type == 'extonly')) {
                showIt = false;
            } 


            //If we want to show it we need to set the secondary value
            if (showIt) {

                //Check to see if the click is for the same story
                if (originalsid == this.insideStory[0].sid) {
                    this.displayInsideStory = !this.displayInsideStory;
                } else {
                    this.displayInsideStory = false;
                }
                //Update the lens position
                this.$store.commit("lensPos", storyValue.pos);

            }



        },
        openVideo() {
            this.$store.commit("updateVideo", this.insideStory[0].int.vid);
            this.$store.commit("toggleVideo");

            this.$nextTick( ()=> {
                this.$root.$refs.storyVid.focus();
            });
        }

    }


};
</script>



<style scoped>

.productPage {
    width:100%;
    height:100%
}

#interaction, #visual {
    width:750px;
    height:620px;
    position:absolute;
    top:0;
    left:0
}
#interaction, 
#visual {
    z-index:0
}
#visual img {
    width:100%
}

#visual .tagLine {
    position: absolute;
    padding: 1rem;
    bottom: 2rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 542px;
    /* height: 67px; */
    left: 3rem;
}
#visual .tagLine .logo.zenotof {
    width: 220px;
}
#visual .tagLine .logo.oneomics {
    color: #FFFFFF;
    font-size: 1.4rem;
    line-height: 1;
    width: 240px;
}
#visual .tagLine .logo.biophase {
    width: 220px;
}

#visual .tagLine .lensInfo {
    color: #FFFFFF;
    text-align: right;
    font-size: 13px;
    padding-left: 3rem;
    width: 280px;
}

.connectingLines {
    width:750px;
    height:620px;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    pointer-events:none
}
.connectingLines svg {
    position:absolute;
    top:0;
    left:0
}
#stories {
    position:absolute;
    top:0;
    right:50px;
    border-left:2px solid #fff;
    width:332px;
    height:100%;
    background-color:rgba(0,0,0,.4);
    /* overflow: hidden; */
}


#stories .story {
    position:absolute;
    border-bottom:1px solid #fff;
    border-top:1px solid #fff;
    color:#fff;
    overflow-y:hidden;
    margin-top:-1px;
    border-left:0 solid #fff;
    bottom:auto;
    transform-origin:100% 50%;
    /* transform:translateY(-50%); */
    z-index:2;
    /* transform:translateX(0%); */
    display:block;
}

#stories .story.index0 {
    top:0;
}
#stories .story.index1 {
    top:189px;
}
#stories .story.index2 {
    top:378px;
}



.list-hidden-lines-enter-active,
.list-hidden-lines-leave-active {
    transition: all 0.2s ease;
}

.list-lines-enter-active,
.list-lines-leave-active,
.list-stories-enter-active,
.list-stories-leave-active {
  transition: all 1s ease;
}

/* Stagger it */
.list-lines-enter-active.index0,
.list-lines-leave-active.index0 {
    transition-delay: 400ms;
}
.list-stories-enter-active.index0,
.list-stories-leave-active.index0 {
    transition-delay: 200ms;
}


.list-lines-enter-active.index1,
.list-lines-leave-active.index1 {
    transition-delay: 600ms;
}
.list-stories-enter-active.index1,
.list-stories-leave-active.index1 {
    transition-delay: 400ms;
}


.list-lines-enter-active.index2,
.list-lines-leave-active.index2 {
    transition-delay: 800ms;
}
.list-stories-enter-active.index2,
.list-stories-leave-active.index2 {
    transition-delay: 600ms;
}



.list-hidden-lines-enter-from,
.list-hidden-lines-leave-to,
.list-lines-enter-from,
.list-lines-leave-to {
    opacity: 0;
}
.list-stories-enter-from,
.list-stories-leave-to {
  opacity: 0;
  transform:translateX(20%);
}





#stories.show .story {
    opacity:.3;
    pointer-events:none
}
#stories.show .story.active {
    opacity:1;
    pointer-events:all;
    transform:scale(1.1) translateY(-50%);
    border-left:5px solid #fff;
    z-index:10
}
#stories.show .story.active:first-child,
#stories.show .story.active:nth-child(3){
    transform:scale(1.1) translateY(0)
}
/* #stories .story {
    transition:all .3s ease-in-out
} */
.pagination {
    position:absolute;
    width:100%;
    height:50px;
    border-top:2px solid #fff;
    bottom:0;
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#fff
}
.pagination div {
    width:50px
}
.pagination button {
    color:#fff;
    background-color:hsla(0,0%,100%,.1);
    border:none;
    width:50px;
    height:50px;
    cursor: pointer;
}
.pagination button:hover {
    background-color:hsla(0,0%,100%,.2);
}
.pagination div:first-child button {
    border-right:1px solid #fff
}
.pagination div:last-child button {
    border-left:1px solid #fff
}
.wipe-enter-active, 
.wipe-leave-active {
    transition:all .5s ease;
    transition-delay:.5s
}
.wipe-enter-from, 
.wipe-leave-to {
    opacity:0
}
.wipe-enter-from {
    transform:translateY(40px)
}
.targeted {
    position:absolute;
    top:0;
    left:50%;
    transform:translateX(-50%);
    /* background-color:red; */
    color:#fff;
    width:100%;
    max-width:200px;
    z-index:800;
    text-align:center
}
.storyLinks {
    list-style:none;
    position:absolute;
    padding:0;
    margin:0;
    z-index:90
}
.storyLinks li {
    opacity:0;
    width:30px;
    height:30px;
    padding:0;
    margin:0;
    position:absolute;
    transform:translate(-50%,-50%)
}
.storyLinks li button {
    width:30px;
    height:30px;
    cursor:pointer;
}
.storyLinks li button.noclick {
    cursor: auto;
}
.overlay {
    position:absolute;
    width:600px;
    height:450px;
    background-color:rgba(0,0,0,.9);
    color:#fff;
    top:50%;
    left:33.3%;
    transform:translate(-50%,-50%);
    z-index:800;
    border:2px solid #fff;
    padding:1.5rem .9rem .9rem .9rem
}
.overlay .storyContent {
    display:flex
}
.overlay .storyContent div {
    margin:.9rem
}
.cbutton {
    text-align:right;
    padding:.2rem;
    width:100%;
    position:fixed;
    top:0;
    right:0
}
.cbutton button {
    position:relative;
    background:none;
    width:30px;
    height:30px;
    border:0;
    outline:0 solid hsla(0,0%,100%,.3);
    background:rgba(0,0,0,.4)
}
.cbutton button :focus{
    outline:2px solid hsla(0,0%,100%,.3);
    background:hsla(0,0%,100%,.3)
}
.cbutton span {
    display:block;
    color:#fff
}
.cbutton span.button-left, 
.cbutton span.button-right {
    border-top:1px solid;
    transform:rotate(45deg);
    transform-origin:center;
    position:absolute;
    top:15px;
    width:20px;
    left:5px;
    transition:all .3s ease-in-out
}
.cbutton span.button-right {
    transform:rotate(-45deg)
}
.hidden {
    pointer-events:none
}
.hidden.clickable {
    pointer-events:all
}




/* Internal Story */
#stories {
    perspective: 500px;
    z-index: 2;
}
.storyFlip {
    position:absolute;
    z-index: 10;
    width: 100%;

    opacity: 1;
    transform: rotateY(0deg);
    transition: all 1s ease;
    transition-delay: 200ms;
}
.storyFlip.hide {
    opacity: 0;
    transform: rotateY(180deg);
}


.insideStory {
    position:absolute;
    z-index: 10;
    width: 100%;
    height:100%;

    /* background-color: rebeccapurple; */
    /* padding: 1.5rem; */

    opacity: 0;
    transform: rotateY(-180deg);

    transition: all 1s ease;
    transition-delay: 200ms;

    pointer-events: none;
}
.insideStory.show {
    opacity: 1;
    transform: rotateY(0deg);
    pointer-events: all;
}

.insideStoryContent {
    overflow-y: auto;
    height: 100%;
    transition: opacity 300ms ease-in-out;
    transition-delay: 300ms;
    opacity: 0;
}
.show .insideStoryContent {
    opacity: 1;
}

.insideStoryContent .visualHeader {
    position: relative;
    width: 100%;
}
.insideStoryContent .visualHeader h1 {
    position: absolute;
    width: 100%;
    bottom: 3px;
    padding: 1rem;
    background:linear-gradient(0deg,rgba(0,0,0,.6),transparent);
    text-shadow: 3px 3px 6px rgba(0,0,0,0.5);
    color: #FFFFFF;
}
.insideStoryContent .visualHeader img {
    width: 100%;
}

.insideStoryContent .storyText {
    padding: 0.2rem 1rem 1rem 1rem;
}

.insideStoryContent .storyText .associate {
    padding: 0.5rem 1rem 0.75rem 1rem;
    margin: -0.5rem -1rem 1rem -1rem;

    background-color: rgba(0,0,0,0.6);
    color: #FFFFFF;

    font-size: 0.8rem;
}
.insideStoryContent .storyText .associate h2 {
    font-weight: bold;
}









/* Animations */
.animation_stage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top:0;
    left: 0;
}
.animation_stage.hide {
    pointer-events: none;
    opacity: 0;
}

.animation_stage.one {
    z-index: 2;
}
.animation_stage.two {
    z-index: 1;
}

.animation_stage.one .logo.oneomics{
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-rendering: optimizeSpeed;
    text-shadow: 0 0 10px rgba(0,0,0,.8);
}

</style>

<style>

.animation_stage.one .logo {
    z-index: 1;
}

.animation_stage.one .gradBG.slideIn { 
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    left:0;
    animation: bgSlideIn 1.5s cubic-bezier(0.83, 0, 0.17, 1) both;
    animation-delay: 0ms;
}

@keyframes bgSlideIn {
    0% { 
        width: 0%;
        opacity: 0.8; 
    }   
    15%, 85% { 
        width: 100%;
        left: 0;
        opacity: 1;
    }   
    100% {
        left: 100%;
        opacity: 1;
    }
}

.animation_stage.one .logo { 
    animation: logoSlideIn 1.5s cubic-bezier(0.83, 0, 0.17, 1) both;
    animation-delay: 300ms;
}
@keyframes logoSlideIn {
    0% { 
        transform: scale(6.5);
        opacity: 0;
    }   
    15%, 85% { 
        transform: scale(1);
        opacity: 1;
    }   
    100% {
        transform: scale(0.2);
        opacity: 0;
    }
}

.animation_stage.one { 
    animation: removeAni 100ms both;
    animation-delay: 650ms;
}

@keyframes removeAni {
    from {
        pointer-events: all;
    }
    to {
        pointer-events: none;
        display: none;
    }
}


.zenotof7600 .animation_stage.two,
.oneomics .animation_stage.two { 
    animation: revealProduct 0.8ms both;
    animation-delay: 1.3s;
}

@keyframes revealProduct {
    from {
        opacity: 0;
        pointer-events: none;
    }
    to {
        opacity: 1;
        pointer-events: all;
    }
}





/* VideoStory */

.clickable {
    cursor: pointer;
    /* margin-top: 3.15rem; */
}
.videoBtn {
    background-color: #000000;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 0.6rem 1rem;
    cursor: pointer;
    transition: all 300ms ease;
}
.videoBtn:hover {
    color: #000;
    background:#6cba33;
    background:linear-gradient(52deg,#249743 10%,#6cba33 46%,#9cd32c 90%)
}




</style>