<template>
    <div ref="draggableContainer" class="lens" :style="'top: '+ cssPosition.y +'px; left: '+ cssPosition.x +'px;'">
       
        <div class="loupe" style="background: rgba(0, 0, 0, 0.4); box-shadow: 3px 3px 8px rgb(0 0 0 / 70%);"></div>
        <!-- <div class="loupe" :style="'background-position: -'+wireFrameDisplay.x+'px -'+wireFrameDisplay.y+'px;'"></div> -->
        <div :class="['loupe', $route.name.toLowerCase().replace(' ', '') ]" :style="'background-position: '+wireFrameDisplay.x+'px '+wireFrameDisplay.y+'px;'"></div>


        <div class="lines" style="width: 1px; height: 114px; border-left: 1px dashed rgba(255, 255, 255, 0.3); top: 3px;"></div>

        <div class="lines" style="width: 114px; height: 1px; border-top: 1px dashed rgba(255, 255, 255, 0.3); left: 3px;"></div>

        <div 
            class="grabber gradBG"
            @mousedown="dragMouseDown"
        >
            <img src="/imgs/lensDirectional_icon.svg" alt="Grabber" />
        </div>

        <div class="zPos"> Z: {{ (cssPosition.x + cssPosition.y / 2).toFixed(0) }}</div>
        
        <!-- This is for node Position Testing -->
        <!-- <div class="testPos"> X: {{ cssPosition.x }} | Y: {{ cssPosition.y }}</div> -->

    </div>
</template>

<script>
export default {
    name: "Lens",
    props: {},
    data: function() {
        return {
            positions: {
                clientX: 0,
                clientY: 0,
                movementX: 0,
                movementY: 0
            }
        }
    },
    computed: {
        displayMessage() {

            let x = this.$store.state.lensPos.x;
            let y = this.$store.state.lensPos.y;
            
            let lensRestPos = { x: 652, y: 521 };

            return ( x <= (lensRestPos.x + 20) && x >= (lensRestPos.x - 20) ) && ( y <= (lensRestPos.y + 20) && y >= (lensRestPos.y - 20) );
        },
        displayPos: function() {
            return {
                x: this.positions.clientX,
                y: this.positions.clientY
            }
        },
        wireFrameDisplay: function() {
            
            let x = this.$store.state.lensPos.x - 57;
            let y = this.$store.state.lensPos.y - 57;
            
            return {
                x: x - (x * 2), //This reverses the number from a positive to a negative and visa-versa
                y: y - (y * 2) //This reverses the number from a positive to a negative and visa-versa
            }
        },
        cssPosition: function() {
            return {
                x: this.$store.state.lensPos.x,
                y: this.$store.state.lensPos.y
            }
        }
    },
    methods: {
        dragMouseDown: function(t) {
            t.preventDefault(),

            this.positions.clientX = t.clientX,
            this.positions.clientY = t.clientY,
            document.onmousemove = this.elementDrag,
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function(t) {
            t.preventDefault();

            this.positions.movementX = this.positions.clientX - t.clientX;
            this.positions.movementY = this.positions.clientY - t.clientY;
            this.positions.clientX = t.clientX;
            this.positions.clientY = t.clientY;

            //Check position
            var lensX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
            var lensY = this.$refs.draggableContainer.offsetTop - this.positions.movementY;

            if ( (lensX > -30 && lensX < 660) && (lensY > 21 && lensY < 525) ) {
                this.$store.commit("lensPos", {
                    x: this.$refs.draggableContainer.offsetLeft - this.positions.movementX,
                    y: this.$refs.draggableContainer.offsetTop - this.positions.movementY
                })
            }
        },
        closeDragElement: function() {
            document.onmouseup = null,
            document.onmousemove = null
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lens {
    position:absolute;
    cursor:move;
    width:120px;
    height:120px;
    top:521px;
    left:652px;
    transform:translate(-50%,-50%);
    z-index:80
}
.lens .lines {
    position:absolute;
    left:50%;
    top:50%
}
.loupe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius:50%;
    border:3px solid #fff;
    /* background:url(/imgs/fpoImg_wire.png) no-repeat; */
    background-size:755px
}
.loupe.zenotof7600 {
    background:url(/imgs/ProductImg_ZenoTOF_wire.png) no-repeat;
    background-size:750px
}        
.loupe.oneomics {
    background:url(/imgs/ProductImg_OneOmics_wire.png) no-repeat;
    background-size:750px
}       
.loupe.biophase {
    background:url(/imgs/ProductImg_BioPhase_wire.png) no-repeat;
    background-size:750px
}        


.grabber {
    position:absolute;
    bottom:0;
    right:0;
    width:40px;
    height:40px;
    border-radius:50%;
    padding: 3px;
}
.grabber img {
    width: 100%;
}
.zPos {
    font-size: 11px; 
    color: rgb(255, 255, 255); 
    top: 0px; 
    left: 0px; 
    border-bottom: 1px solid rgb(255, 255, 255); 
    padding: 2px 2px 2px 0px; 
    width: 33px; 
    margin-left: -12px;
}
.testPos {
    font-size: 11px; 
    color: rgb(255, 255, 255); 
    top: 0px; 
    left: 0px; 
    border-bottom: 1px solid rgb(255, 255, 255); 
    padding: 2px 2px 2px 0px; 
    width: 70px; 
    margin-top: -42px;
    margin-left: -32px;
}
</style>
